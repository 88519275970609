import Meta from 'components/meta/meta'
import Rich from 'components/rich/rich'
import Panel from 'components/panel/panel'
import { getData, getList } from 'utils/api'
import styles from 'styles/page/static.module.scss'
import { cn } from 'utils/helpers'

export default function Page({ name, description, content, noindex }) {
    return (
        <div className={styles.page}>
            <Meta title={name} description={description} noindex={noindex} />
            <Panel className={cn(styles.panel, 'mb72')} desktop>
                <Rich content={content} />
            </Panel>
        </div>
    )
}

export async function getStaticProps(context) {
    return getData(`static-page/${context.params.slug}`)
}

export async function getStaticPaths() {
    return getList('static-pages-list')
}
