import styles from './rich.module.scss'
import { cn } from 'utils/helpers'

export default function Rich({ content, alt }) {
    return (
        <div
            className={cn(styles.rich, alt && styles.alt)}
            dangerouslySetInnerHTML={{
                __html: content
            }}
        />
    )
}
